<template>
  <van-pull-refresh
    class="page"
    v-model="state.loading"
    success-text="刷新成功"
    loosing-text="Beta版1.0.0.211022"
    @refresh="loadBalance"
  >
    <van-image
      width="100%"
      :height="config.appBannerHeight"
      fit="cover"
      :src="
        config.appBanner
          ? config.appBanner
          : require('@/assets/images/banner.png')
      "
    />
    <van-row class="home-links">
      <van-col span="6">
        <router-link :to="{ name: 'UserProfile' }">
          <van-icon v-if="!state.headimgurl" name="user-circle-o" />
          <transition name="fade">
            <img
              v-if="state.headimgurl"
              :src="state.headimgurl"
              class="home-avatar"
            />
          </transition>
        </router-link>
        <div class="van-ellipsis">{{ state.nickname }}</div>
      </van-col>
      <van-col span="6">
        <router-link :to="{ name: 'UserRecharge' }">
          <van-icon name="gold-coin-o" />
        </router-link>
        <div class="van-ellipsis">{{ state.balanceTips }}</div>
      </van-col>
      <van-col span="6">
        <van-icon name="scan" @click="clearRechargeCode" />
        扫码充电
      </van-col>
      <van-col span="6">
        <router-link :to="{ name: 'PowerNearby', query: { ref: 'home' } }">
          <van-icon name="location-o" />
        </router-link>
        附近充电站
      </van-col>
    </van-row>

    <van-cell-group class="home-group">
      <van-cell
        icon="orders-o"
        title="充电记录"
        :to="{ name: 'UserChargingOrder' }"
        is-link
      />
      <van-cell
        icon="gold-coin-o"
        title="现金余额"
        :to="{ name: 'BalanceCash' }"
        :value="state.balance == '点此充值' ? '¥0' : `¥${state.balance}`"
        is-link
      />
      <van-cell
        icon="point-gift-o"
        title="赠金余额"
        :to="{ name: 'BalanceGift' }"
        :value="`¥${state.giftBalance}`"
        is-link
      />
    </van-cell-group>

    <van-cell-group class="home-group" v-if="state.is_agent || state.is_profit">
      <van-cell
        v-if="state.is_agent"
        icon="apps-o"
        title="管理中心"
        @click="loadAgentInfo"
        is-link
      />
      <van-cell
        v-if="!state.is_agent && state.is_profit"
        icon="balance-o"
        title="分润钱包"
        :to="{ name: 'AgentWallet' }"
        is-link
      />
    </van-cell-group>

    <van-cell-group class="home-group">
      <van-cell
        v-if="state.hotline"
        icon="phone-o"
        title="联系客服"
        is-link
        @click="callHotline"
      />
      <van-cell
        icon="info-o"
        title="服务协议"
        is-link
        :to="{ name: 'Agreement' }"
      />
    </van-cell-group>
  </van-pull-refresh>
</template>

<script>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import config from "@/config/config.local";
import { onMounted, onUnmounted, reactive } from "vue";
import { Row, Col, Dialog, Toast, PullRefresh, Image as VanImage } from "vant";
import { VERIFY_AGENT, LOGOUT } from "@/store/modules/auth";
import { heartbeat, getBalance } from "@/api/account.service";
import { getServiceHotline } from "@/api/option.service";
export default {
  name: "Home",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Dialog.name]: Dialog,
    [PullRefresh.name]: PullRefresh,
    [VanImage.name]: VanImage,
  },

  setup() {
    let timer = null;
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      nickname: "个人中心",
      balanceTips: "点此充值",
      headimgurl: null,
      is_agent: 0,
      balance: 0,
      giftBalance: 0,
      hotline: "",
      loading: false,
    });

    const loadBalance = async () => {
      try {
        const { result } = await getBalance();
        state.balance = result.balance;
        state.giftBalance = result.giftBalance;
        // 现金余额大于0时, 图标下显示总余额, 否则显示 “点此充值”
        if (state.balance > 0) {
          setTimeout(() => {
            state.balanceTips = new Number(
              state.balance + state.giftBalance
            ).toFixed(2);
          }, 5000);
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
      state.loading = false;
    };

    const loadAgentInfo = async () => {
      Toast.loading({
        message: "加载模块",
        forbidClick: true,
        duration: 0,
      });
      store
        .dispatch(VERIFY_AGENT)
        .then(({ result }) => {
          if (result.level == 1) {
            router.push({ name: "GeneralAgent" });
          } else if (result.level == 2) {
            router.push({ name: "Agent" });
          }
          Toast.clear();
        })
        .catch((error) => {
          const { data, statusText } = error;
          const msg = data && data.msg ? data.msg : statusText;
          Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
          if (!msg) console.log(error);
          Toast.clear();
        });
    };

    const clearRechargeCode = () => {
      window.sessionStorage.removeItem("power.recharge.code");
      router.push({ name: "PowerRecharge", query: { ref: "home" } });
    };

    const checkHeartbeat = async () => {
      try {
        await heartbeat();
      } catch (error) {
        store.dispatch(LOGOUT).then(() => {
          router.push({ name: "Login", query: { action: "heartbeat-fail" } });
        });
      }
    };

    const getHotline = async () => {
      try {
        const { result } = await getServiceHotline();
        state.hotline = result.hotline;
        window.sessionStorage.setItem("serviceHotline", result.hotline);
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        // Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (msg) {
          console.log(msg);
        } else {
          console.log(error);
        }
      }
    };

    const callHotline = () => {
      window.location.href = `tel:${state.hotline}`;
    };

    onMounted(() => {
      checkHeartbeat();
      state.hotline = window.sessionStorage.getItem("serviceHotline");
      if (!state.hotline) {
        getHotline();
      }
      const { nickname, headimgurl, is_agent, is_profit } =
        store.getters.currentUser;
      state.is_agent = is_agent;
      state.is_profit = is_profit;
      setTimeout(() => {
        state.nickname = nickname;
        state.headimgurl = headimgurl;
        loadBalance();
      }, 1200);
      timer = setInterval(() => {
        checkHeartbeat();
      }, 30000);

      // 从服务消息跳转来的访客, 直接转入订单列表页
      // 当 window.history.state.forward == null 时表示是首次进入首页, 可以进行跳转操作
      const { redirect, trade_no } = route.query;
      if (!window.history.state.forward) {
        if (redirect == "chargingorder") {
          router.push({ name: "UserChargingOrder" });
        } else if (redirect == "recharge-detail") {
          router.push({ name: "UserRechargeDetail", query: { trade_no } });
        }
      }
    });

    onUnmounted(() => {
      try {
        clearInterval(timer);
      } catch (error) {
        // ...
      }
    });

    return {
      config,
      state,
      loadBalance,
      loadAgentInfo,
      clearRechargeCode,
      callHotline,
    };
  },
};
</script>

<style lang="less" scoped>
.home {
  &-group {
    margin-bottom: 15px;
  }
  &-links {
    margin: -5px 0 0 0;
    padding: 15px 10px;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      margin-bottom: 6px;
      font-size: 30px;
      color: #313131;
    }
  }
  &-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-bottom: 6px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
